/*--------------------
  ICONS-LIST
--------------------*/

.icons-list {
  text-align: center;
  
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    list-style: none;
  }

  li {
    width: 100%;
    max-width: 27rem;
    margin: 0 auto 3rem;

    @media screen and (min-width: $md-min) {
      max-width: 31.7%;
      margin: 0 0 4rem;
    }
  }

  img {
    max-width: 7.2rem;
    margin: 0 auto 1rem;
  }

  &__title {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  &__text {
    margin: 0;
    font-size: 2rem;
    line-height: 1.5;
  }
}