/*--------------------
  GAME
--------------------*/

.games {
  &:not(:last-child) {
    margin: 0 0 1rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 2rem;
    }
  }

  ul {
    list-style: none;

    @media screen and (min-width: $lg-min) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  li {
    margin-bottom: 1rem;

    @media screen and (min-width: $lg-min) {
      width: 17.4rem;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }

  a {
    display: flex;
    align-items: center;
    padding: 1.2rem 0.5rem 1.2rem 9rem;
    color: #484040;
    text-decoration: none;
    font-size: 2rem;
    font-weight: 600;
    background: #FFFFFF;
    border: 0.1rem solid rgba(0, 0, 0, 0.15);
    border-radius: 1.5rem;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    transition: 0.2s border-color, 0.2s color;

    @media screen and (min-width: $lg-min) {
      display: block;
      height: 17.4rem;
      padding: 3.2rem 1rem;
      text-align: center;
      border-color: #ffffff;
    }

    &:hover {
      color: $c-main;
      border-color: #484040;
    }
  }

  img {
    flex-shrink: 0;
    width: 3.1rem;
    max-height: 3.1rem;
    margin: 0 1.3rem 0 0;

    @media screen and (min-width: $lg-min) {
      display: block;
      width: 6.2rem;
      max-height: 100%;
      margin: 0 auto 1.7rem;
    }
  }
}