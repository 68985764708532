/*--------------------
  BONUSES
--------------------*/

.bonuses {
  text-align: center;

  @media screen and (min-width: $xl-min) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .referral-btn {
    width: 100%;
    text-decoration: none;
    color: $c-cta;
    padding: 1.4rem 0.5rem;
    font-weight: 600;
    font-size: 1.8rem;
    border-radius: 1rem;
    border: 0.1rem solid $c-cta;
    background: rgba(221, 252, 83, 0.05);
    transition: background 0.2s;
    cursor: pointer;

    &:hover {
      background: rgba(221, 252, 83, 0.1);
    }
  }

  &__col {
    max-width: 100%;
    width: 32.8rem;
    margin: 0 auto;

    @media screen and (min-width: $md-min) {
      width: 56.2rem;
    }

    @media screen and (min-width: $xl-min) {
      margin: 0;
    }

    &:nth-child(2) {
      @media screen and (min-width: $md-min) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  &__card {
    margin: 0 0 1rem;
    padding: 17rem 1.5rem 2.5rem;
    background: #111111 center top / 100% auto no-repeat;
    border-radius: 1.2rem;

    @media screen and (min-width: $md-min) {
      border-radius: 1.5rem;
    }

    @media screen and (min-width: $xl-min) {
      margin-bottom: 2rem;
    }

    &--one {
      background-image: url("../img/bonuses-1.webp");
      background-position: center top -1.3rem;

      @media screen and (min-width: $md-min) {
        padding: 24rem 2.4rem 2.7rem;
        background-position: center top;
      }

      .referral-btn {
        background: $c-cta;
        color: #333333;

        &:hover {
          background: darken($c-cta, 17%);
        }
      }

      .bonuses__title {
        @media screen and (min-width: $md-min) {
          margin-bottom: 1.5rem;
          font-size: 3.3rem;
        }
      }

      .bonuses__text {
        @media screen and (min-width: $md-min) {
          margin-bottom: 3rem;
          font-size: 2.4rem;
          font-weight: 700;
        }
      }
    }

    &--two {
      position: relative;
      padding-top: 22rem;

      @media screen and (min-width: $md-min) {
        padding: 3rem 25rem 3.9rem 3rem;
        min-height: 24.6rem;
        text-align: left;
      }

      &::before {
        content: "";
        position: absolute;
        top: 2rem;
        left: 50%;
        background: url("../img/bonuses-2.webp") left top no-repeat;
        background-size: 100%, auto;
        transform: translate(-50%, 0);

        @include size(18.2rem, 17.9rem);

        @media screen and (min-width: $md-min) {
          top: -2.8rem;
          right: -1.2rem;
          left: auto;
          transform: none;

          @include size(29rem, 28.4rem);
        }
      }

      .referral-btn {
        @media screen and (min-width: $md-min) {
          max-width: 23.1rem;
        }
      }

      .bonuses__title {
        @media screen and (min-width: $md-min) {
          margin-bottom: 0.5rem;
          font-size: 3.3rem;
        }
      }

      .bonuses__text {
        @media screen and (min-width: $md-min) {
          margin-bottom: 2rem;
          font-size: 2.4rem;
          font-weight: 700;
          line-height: 1.2;
        }
      }
    }

    &--three,
    &--four {
      @media screen and (min-width: $md-min) {
        padding: 14rem 2.4rem 2.8rem;
        max-width: 27.1rem;
        min-height: 21.4rem;
      }

      @media screen and (min-width: $xl-min) {
        margin-top: auto;
      }
    }

    &--three {
      background-image: url("../img/bonuses-3.webp");
    }

    &--four {
      background-image: url("../img/bonuses-4.webp");
    }
  }

  &__logo {
    display: none;

    @media screen and (min-width: $md-min) {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 2.4rem;
      padding: 1.5rem 1.7rem;
      border-radius: 50%;
      background: #000000;
      border: 0.1rem solid rgba(255, 255, 255, 0.3);

      @include size(12.2rem, 12.2rem);
    }
  }

  &__title {
    margin: 0;
    color: #ffffff;
    font-weight: 500;
    font-size: 2.6rem;
    line-height: 1.2;
  }

  &__text {
    margin: 0 0 1rem;
    font-size: 1.8rem;
    color: #FDC246;
    font-weight: 500;
    line-height: 1.2;
  }
}