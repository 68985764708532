/*--------------------
  CASINO-BONUSES
--------------------*/

.casino-bonuses {
  margin: 0.5rem 0 1rem;

  @media screen and (min-width: $lg-min) {
    margin: 1rem 0 1.5rem;
  }

  ul {
    list-style: none;

    @media screen and (min-width: $md-min) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  li {
    max-width: 33rem;
    margin: 0 auto 1.5rem;
    padding: 2rem 2.2rem  ;
    text-align: center;
    background: #FFFFFF;
    border: 0.1rem solid #D0D0D0;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    border-radius: 1.5rem;

    @media screen and (min-width: $md-min) {
      margin-right: 0.3rem;
      margin-left: 0.3rem;
    }

    @media screen and (min-width: $lg-min) {
      max-width: 27.1rem;
    }

    @media screen and (min-width: $xl-min) {
      margin: 0 0 2rem
    }
  }

  .button {
    margin: 0 auto;
    max-width: 100%;
  }

  &__logo {
    margin: 0 auto 1.2rem;
    
    @include size(28.5rem, 14.9rem);
    
    @media screen and (min-width: $lg-min) {
      @include size(22.3rem, 11.3rem);
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__desc {
    margin: 0 auto 1rem;
    line-height: 1.2;
    color: #4C4100;
    font-size: 1.4rem;
    font-weight: 500;
  }

  &__text {
    margin: 0 auto 1rem;
    color: #4C4100;
    font-weight: 700;
  }
}