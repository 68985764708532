/*--------------------
  STEPPER
--------------------*/

.stepper {
  @media screen and (min-width: $lg-min) {
    padding: 0 5rem 0 0;
  }

  &:not(:last-child) {
    margin: 0 0 2.5rem;
  
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  &__list {
    list-style: none;
    counter-reset: list-number;

    li {
      position: relative;
      padding: 0 0 0 4.6rem;
      counter-increment: list-number;

      &:not(:last-child) {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 1.7rem;
          bottom: -1.2rem;
          background: #484040;
          width: 0.3rem;
        }
      }

      &:last-child {
        &::after {
          content: "";
          line-height: 1;
          background: $c-main url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMSAxNyI+PHBhdGggZmlsbD0iI0VDRjBGMSIgZD0ibTE2Ljg3NS44NS05IDktMy43NS0zLjc1TC45MzcgOS4yNSA0LjY4OCAxM2wzIDMgLjE4OC4xNUwyMC4wNjMgNCAxNi44NzUuODVaIi8+PC9zdmc+") center center / 1.9rem 1.5rem no-repeat;
        }

        p, .stepper__title {
          color: $c-main;
        }
      }

      &:nth-child(n+2) {
        margin-top: 1.2rem;
      }

      &::after {
        content: counter(list-number);
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        line-height: 1;
        font-size: 1.8rem;
        font-weight: 700;
        border-radius: 50%;
        background: #484040;
        border: 0.4rem solid #f9f9f9;

        @include size(3.7rem, 3.7rem);
      }
    }

    p {
      margin: 0;
      color: #000000;
      font-size: 2rem;
      line-height: 1.3;
    }
  }

  &__title {
    margin: 0 0 0.5rem;
    line-height: 1.2;
    color: #484040;
    font-size: 2rem;
  }
}