/*--------------------
  TOP-CARD
--------------------*/

.top-card {
  display: flex;
  padding: 2.5rem 1.5rem 3rem;
  border-radius: 1.5rem;
  border: 0.2rem solid transparent;
  background: #ffffff;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);

  @media screen and (max-width: $md-max) {
    flex-direction: column;
  }

  @media screen and (min-width: $lg-min) {
    padding: 1.5rem 2rem 1.5rem 6rem;
    align-items: center;
  }

  .rating {
    margin: 0 0.3rem;
  }

  &__logo {
    display: flex;
    justify-content: center;
    width: 10.5rem;
    margin: 0 auto 2rem;
    padding: 0;
    border: none;
    cursor: pointer;
    background: none;

    @media screen and (max-width: $md-max) {
      order: 1;
    }

    @media screen and (min-width: $lg-min) {
      display: block;
      flex-shrink: 0;
      width: 8rem;
      margin: 0 2.5rem 0 0;
    }

    img {
      max-width: 100%;
      height: auto;

      @media screen and (min-width: $lg-min) {
        display: block;
      }
    }
  }

  &__main-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 1.5rem;

    @media screen and (max-width: $md-max) {
      order: 2;
    }

    @media screen and (min-width: $lg-min) {
      flex-shrink: 0;
      display: block;
      width: 10%;
      margin: 0 3rem 0 0;
    }
  }

  &__name {
    margin: 0;
    font-size: 1.8rem;
    color: #000000;
    font-weight: 700;
  }

  &__link {
    color: #000000;
    font-size: 1.4rem;

    &:hover {
      text-decoration: none;
    }
  }

  &__secondary-info {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0 0 1.5rem;
    line-height: 1.2;
    color: #404040;

    @media screen and (max-width: $md-max) {
      order: 2;
    }

    @media screen and (min-width: $lg-min) {
      flex-grow: 1;
      flex-shrink: 0;
      align-items: center;
      width: 32.5%;
      margin-bottom: 0;
    }

    li {
      display: flex;
      flex-shrink: 0;

      @media screen and (min-width: $lg-min) {
        align-items: center;
      }  

      &:first-child {
        width: 62%;
      }

      &:last-child {
        width: 33%;
      }
    }

    svg {
      flex-shrink: 0;
      margin-right: 0.7rem;
      stroke: #404040;

      @include size(2.4rem, 2.4rem);
    }

    span {
      font-size: 1.6rem;
      font-weight: 700;
    }
  }

  &__payments {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    list-style: none;

    @media screen and (max-width: $md-max) {
      order: 4;
    }

    @media screen and (min-width: $lg-min) {
      flex-shrink: 0;
      width: 15%;
      margin: 0 2rem 0 0;
    }

    li {
      display: flex;
      align-items: center;
      padding: 0 0.3rem 0.5rem;
    }

    img {
      max-width: 3rem;
      max-height: 3rem;

      @include size(100%, auto);
    }
  }

  &__btn {
    display: block;
    width: 30rem;
    max-width: 100%;
    margin: 0 auto 1.5rem;
    padding: 1.4rem 0.5rem;
    font-weight: 600;
    color: #333333;
    background: rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    text-decoration: none;
    transition: 0.2s background;
    cursor: pointer;

    @media screen and (max-width: $md-max) {
      order: 3;
    }

    @media screen and (min-width: $lg-min) {
      flex-shrink: 0;
      width: 10.1rem;
      margin: 0;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &--top {
    background: #101010;

    .top-card__main-info {
      display: block;
      margin-right: auto;
      margin-left: auto;
      text-align: center;

      @media screen and (min-width: $lg-min) {
        margin-right: 3rem;
        margin-left: 0;
        text-align: left;
      }
      
      .top-card__name,
      .top-card__link {
        color: #ffffff;
      }
    }

    .top-card__secondary-info {
      color: #fdc246;

      svg {
        stroke: #fdc246;
      }
    }

    .top-card__btn {
      background: $c-cta;
      border-color: $c-cta;

      &:hover {
        background: darken($c-cta, 20%);
      }
    }
  }

  &--top-three {
    border-color: #101010;
  }
}