/*--------------------
  INTRO_HOME
--------------------*/

.intro-home {
  padding: 2rem 0 5rem;
  text-align: center;
  color: #ffffff;
  font-size: 1.8rem;
  font-weight: 500;
  background: #323232 url("../img/intro-home_bg-xs.webp") center top / cover no-repeat;

  @media screen and (min-width: $sm-min) {
    background-image: url("../img/intro-home_bg-sm.webp");
  }

  @media screen and (min-width: $md-min) {
    background-image: url("../img/intro-home_bg-md.webp");
  }

  @media screen and (min-width: $lg-min) {
    padding: 8.5rem 0 1rem;
    background-image: url("../img/intro-home_bg-lg.webp");
  }

  @media screen and (min-width: $xl-min) {
    background-image: url("../img/intro-home_bg-xl.webp");
  }

  &__title {
    margin: 0 0 1.5rem;
    color: $c-main-light;
    font-size: $f-size-main-title-xs;
    font-weight: 700;
    line-height: 1.1;

    @media screen and (min-width: $lg-min) {
      font-size: $f-size-main-title-lg;
    }

    br {
      @media screen and (max-width: $md-max) {
        display: none;
      }
    }
  }

  &__text {
    margin: 0 0 4rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 7rem;
      font-size: 2rem;
    }

    br {
      @media screen and (max-width: $md-max) {
        display: none;
      }
    }
  }

  &__list {
    list-style: none;

    @media screen and (min-width: $md-min) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    li {
      @media screen and (min-width: $md-min) {
        margin: 0 1rem 1.5rem;
      }

      &:nth-child(n+2) {
        @media screen and (max-width: $sm-max) {
          margin-top: 1.5rem;
        }
      }

      &:first-child {
        @media screen and (min-width: $xl-min) {
          margin-left: 0;
        }
      }

      &:last-child {
        @media screen and (min-width: $xl-min) {
          margin-right: 0;
        }
      }
    }
    
    a {
      display: flex;
      width: 21rem;
      max-width: 100%;
      margin: 0 auto;
      padding: 1.2rem 0.5rem 1.2rem 1.5rem;
      align-items: center;
      background: #ffffff;
      border-radius: 1.2rem;
      color: #484040;
      font-weight: 600;
      font-size: 2rem;
      text-decoration: none;
      transition: 0.2s color;

      &:hover,
      &:focus {
        color: $c-main;
      }
    }

    svg {
      margin-right: 1.7rem;

      @include size(3.2rem, 3.2rem);
    }
  }
}