/*--------------------
  BUTTON-SCROLL-UP
--------------------*/

.button-scroll-up {
  position: fixed;
  right: 1.5rem;
  bottom: 9.5rem;
  z-index: 99;
  display: none;
  color: #C4C4C4;
  font-size: 1.2rem;
  font-weight: 600;
  background: none;
  border: none;
  transition: color 0.2s;
  cursor: pointer;

  @media screen and (min-width: $lg-min) {
    font-size: 2rem;
  }

  &::before {
    content: "";
    position: absolute;
    top: -4.5rem;
    left: 50%;
    border-radius: 0.8rem;
    background: #c4c4c4 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzMSAzMyI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTUuMjMuMDU4Yy0uMDkyLjA0LTMuNTQzIDMuNDA4LTcuNjcgNy40ODVDMS43OTIgMTMuMjQuMDQ1IDE1LjAxLjAwOCAxNS4yYy0uMDU4LjI5LjIyLjc1Ny41NDcuOTIuMTU2LjA3OCAxLjE5Mi4xMDMgNC4zLjEwNGw0LjA5Ni4wMDEuMDM4IDE0Ljc3OWEyIDIgMCAwIDAgMiAxLjk5NWg4Ljc1N2EyIDIgMCAwIDAgMi0xLjk2NWwuMjYtMTQuODA5aDQuMDc4YzQuNSAwIDQuNTE4LS4wMDIgNC43NjgtLjQ5Ni4zMjgtLjY0OS44NC0uMDgtNy40MTEtOC4yMkMxNS41OS0uMjM1IDE1LjY4OS0uMTQ1IDE1LjIyOS4wNThaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48L3N2Zz4=") center center / 2.2rem 2.4rem no-repeat;
    transform: translate(-50%, 0);
    transition: background 0.2s;

    @include size(3.8rem, 3.8rem);

    @media screen and (min-width: $lg-min) {
      top: -6rem;
      border-radius: 1.5rem;
      background-size: 3.1rem 3.3rem;

      @include size(5.3rem, 5.3rem);
    }
  }

  &:hover {
    color: $c-main-light;

    &::before {
      background-color: $c-main-light;
    }
  }
}