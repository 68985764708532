/*--------------------
  SLOTS-TYPES
--------------------*/

.slot-types {
  @media screen and (max-width: $sm-max) {
    text-align: center;
  }

  &:not(:last-child) {
    margin: 0 0 2.5rem;
    
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  ul {
    list-style: none;
  }

  li {
    &:nth-child(n+2) {
      margin-top: 1rem;
    }
  }

  a {
    padding: 1rem 0.8rem 1.8rem;
    background: #FFFFFF;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    border-radius: 1.5rem;
    text-decoration: none;
    color: #333333;
    transition: color 0.2s;

    @media screen and (min-width: $md-min) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2.2rem 2.2rem 2.2rem 3.3rem;
    }

    &:hover {
      color: $c-main;
    }
  }

  &__img {
    width: 100%;
    max-width: 31.1rem;
    height: 22.7rem;
    margin: 0 auto 2rem;
    border-radius: 1.5rem;
    overflow: hidden;

    @media screen and (min-width: $md-min) {
      order: 2;
      flex-shrink: 0;
      margin: 0;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__info {
    @media screen and (max-width: $sm-max) {
      padding: 0 1rem;
    }

    @media screen and (min-width: $md-min) {
      order: 1;
      padding-right: 2.5rem;
    }
  }

  &__title {
    margin: 0 0 0.5rem;
    font-size: 2.4rem;
    font-weight: 600;
  }

  &__text {
    margin: 0;
    font-size: 2rem;
  }
}