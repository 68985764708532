/*--------------------
  PAYMENT_INFO
--------------------*/

.payment-info {
  font-size: 2rem;

  &:not(:last-child) {
    margin-bottom: 2rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 2.5rem;
    }
  }

  ul, ol {
    list-style: none;

    @media screen and (min-width: $lg-min) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  li {
    padding: 2.5rem 2rem 2rem;
    border-radius: 1.5rem;
    background: #ffffff;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);

    @media screen and (min-width: $lg-min) {
      width: 32.2%;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $md-max) {
        margin-top: 2rem;
      }
    }

    &:nth-child(n+4) {
      @media screen and (min-width: $lg-min) {
        margin-top: 2rem;
      }
    }
  }

  h2, h3, h4, h5, h6 {
    margin: 0;
    font-size: 2.4rem;
    line-height: 1;
    color: $c-main;
  }

  p {
    margin: 0;
    color: #000000;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin: 0 0 1rem;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 1.5rem;
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem 0 0;
    padding: 0.8rem 0.9rem;
    border: 0.1rem solid #d9d9d9;
    border-radius: 0.6rem;

    @include size(7rem, 4.8rem);
  }
}