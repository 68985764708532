/*--------------------
  BUTTON
--------------------*/

.button {
  display: block;
  max-width: 32.8rem;
  padding: 1rem 0.5rem;
  text-align: center;
  color: #ffffff;
  font-size: $f-size;
  font-weight: 600;
  text-decoration: none;
  background: $c-main;
  border-radius: 1rem;
  transition: 0.2s background;
  border: none;
  cursor: pointer;

  @media screen and (min-width: $lg-min) {
    max-width: 18rem;
  }

  &:hover {
    background: darken($c-main, 5%);
  }
}