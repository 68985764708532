// COLOR
$c-text: #2f2f2f;
$c-main: #e51a38;
$c-main-light: #ec566c;
$c-cta: #ddfc53;
$c-danger: $c-main;
$c-danger-light: rgba(229, 26, 56, 0.1);
$c-success: #2ab03c;
$c-success-light: rgba(42, 176, 60, 0.1);

// FONT
$f-family: "Baloo 2", sans-serif;
$f-size: 1.8rem;
$f-size-main-title-xs: 4rem;
$f-size-main-title-lg: 5rem;
$f-size-title-xs: 3rem;
$f-size-title-lg: 3.2rem;

// MEDIA QUERIES
$sm-min: 576px;
$md-min: 768px;
$lg-min: 992px;
$xl-min: 1200px;

$xs-max: 575px;
$sm-max: 767px;
$md-max: 991px;
$lg-max: 1199px;

// HEADER HEIGHT
$header-height: 6.5rem;
