/*--------------------
  RTP-LIST
--------------------*/

.rtp-list {
  margin: 0 0 2.5rem;

  @media screen and (min-width: $lg-min) {
    margin: 4rem 0;
    padding: 4rem 0 0;
    border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
  }

  .heading {
    margin: 0 0 1rem;
    line-height: 1.2;
  }

  .rtp-card {
    &:nth-child(n+2) {
      margin-top: 1.5rem;

      @media screen and (min-width: $lg-min) {
        margin-top: 2rem;
      }
    }
  }

  &__items {
    list-style: none;
  }
}