/*--------------------
  GIFT
--------------------*/

.gift {
  position: relative;
  top: -0.3rem;

  @media screen and (min-width: $lg-min) {
    margin-left: 2rem;
  }

  svg {
    display: block;

    @include size(3rem, 3rem);

    @media screen and (min-width: $lg-min) {
      @include size(4rem, 4rem);
    }
  }

  &__label {
    position: absolute;
    bottom: -0.7rem;
    left: -0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: #F0C419;
    font-size: 1.4rem;
    font-weight: 700;
    background: #101010;
    border-radius: 50%;
    border: 0.2rem solid #ffffff;

    @include size(2.2rem, 2.2rem);

    @media screen and (min-width: $lg-min) {
      font-size: 1.8rem;

      @include size(2.6rem, 2.6rem);
    }
  }
}