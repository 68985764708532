/*--------------------
  CHECK-LIST
--------------------*/

.check-list {
  font-size: 2rem;

  &:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  ul, ol {
    list-style: none;
  }

  li {
    &:nth-child(n+2) {
      margin-top: 2.5rem;
    }

    a {
      color: $c-main;
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
    }
  }

  h2, h3, h4, h5, h6 {
    margin: 0 0 0.5rem;
    font-size: 2.4rem;
    line-height: 1.4;

    &::before {
      content: "";
      position: relative;
      top: 0.3rem;
      display: inline-block;
      margin: 0 1rem 0 0;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMyAyMyI+PHBhdGggZmlsbD0iIzMzMyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNSAyMi4zYTEwLjggMTAuOCAwIDEgMCAwLTIxLjYgMTAuOCAxMC44IDAgMCAwIDAgMjEuNlptNS4wMDUtMTIuNTQ1YTEuMzUgMS4zNSAwIDAgMC0xLjkxLTEuOTFsLTQuNDQ1IDQuNDQ2LTEuNzQ1LTEuNzQ1YTEuMzUgMS4zNSAwIDAgMC0xLjkxIDEuOTA5bDIuNyAyLjdhMS4zNSAxLjM1IDAgMCAwIDEuOTEgMGw1LjQtNS40WiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+") top left no-repeat;

      @include size(2.2rem, 2.2rem);
    }
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .button {
    margin: 0 auto;
  }

  &--cols {
    &:not(:last-child) {
      margin-bottom: 6rem;

      @media screen and (min-width: $lg-min) {
        margin-bottom: 8rem;
      }
    }
  
    ul, ol {
      margin-bottom: 4rem;

      @media screen and (min-width: $lg-min) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    li {
      @media screen and (min-width: $lg-min) {
        width: 48%;
      }
    }

    h2, h3, h4, h5, h6 {
      color: #5AC089;

      &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyMyAyMyI+PHBhdGggZmlsbD0iIzVBQzA4OSIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMTEuNSAyMi4wMzhhMTAuOCAxMC44IDAgMSAwIDAtMjEuNiAxMC44IDEwLjggMCAwIDAgMCAyMS42Wm01LjAwNS0xMi41NDVhMS4zNSAxLjM1IDAgMCAwLTEuOTEtMS45MDlMMTAuMTUgMTIuMDNsLTEuNzQ1LTEuNzQ2YTEuMzUgMS4zNSAwIDAgMC0xLjkxIDEuOTA5bDIuNyAyLjdhMS4zNSAxLjM1IDAgMCAwIDEuOTEgMGw1LjQtNS40WiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

        @media screen and (min-width: $lg-min) {
          top: 0.6rem;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzMCAzMiI+PHBhdGggZmlsbD0iIzJDQTY3MiIgZD0iTTMwIDE2LjVjMCA4LjI4NS02LjcxNSAxNS0xNSAxNS04LjI4NCAwLTE1LTYuNzE1LTE1LTE1IDAtOC4yODQgNi43MTYtMTUgMTUtMTUgOC4yODUgMCAxNSA2LjcxNiAxNSAxNVoiLz48cGF0aCBmaWxsPSIjNUFDMDg5IiBkPSJNMzAgMTVjMCA4LjI4NS02LjcxNSAxNS0xNSAxNS04LjI4NCAwLTE1LTYuNzE1LTE1LTE1QzAgNi43MTYgNi43MTYgMCAxNSAwYzguMjg1IDAgMTUgNi43MTYgMTUgMTVaIi8+PHBhdGggZmlsbD0iIzJDQTY3MiIgZD0ibTIxIDEwLjUtOSA5LTMuNzUtMy43NS0zLjE4OCAzLjE1IDMuNzUgMy43NSAzIDMgLjE4OC4xNSAxMi4xODgtMTIuMTVMMjEgMTAuNVoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMjEgOS05IDktMy43NS0zLjc1LTMuMTg4IDMuMTUgMy43NSAzLjc1IDMgMyAuMTg4LjE1IDEyLjE4OC0xMi4xNUwyMSA5WiIvPjwvc3ZnPg==");

          @include size(3rem, 3rem);
        }
      }
    }

    p {
      color: #000000;

      @media screen and (min-width: $lg-min) {
        padding: 0 0 0 4rem;
      }
    }
  }
}