/*--------------------
  DARK-BLOCKS
--------------------*/

.dark-blocks {
  color: #ffffff;

  @media screen and (min-width: $md-min) {
    display: flex;
    justify-content: space-between;
  }

  &:not(:last-child) {
    margin: 0 0 4rem;
  }

  &__item {
    padding: 3rem 2rem;
    border-radius: 0.4rem;
    background: #101010;

    @media screen and (min-width: $md-min) {
      width: 49.5%;
    }

    @media screen and (min-width: $lg-min) {
      padding: 5.3rem 9rem;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $sm-max) {
        margin-top: 1.5rem;
      }
    }
  }

  &__title {
    margin: 0 0 0.5rem;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.3;
  }

  &__text {
    margin: 0;

    b {
      color: $c-main;
    }
  }
}