/*--------------------
  HEADING
--------------------*/

.heading {
  margin: 0;
  color: $c-main;
  font-size: $f-size-title-xs;

  @media screen and (min-width: $lg-min) {
    font-size: $f-size-title-lg;
  }

  &--alt {
    color: #000000;
  }

  &--grey {
    color: #484040;
  }

  &--big {
    font-size: $f-size-main-title-xs;

    @media screen and (min-width: $lg-min) {
      font-size: $f-size-main-title-lg;
    }
  }
}