/*--------------------
  WELCOME-BONUS
--------------------*/

.welcome-bonus {
  z-index: 99;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  min-width: 36rem;
  padding: 1.4rem 0;
  background: rgba(255, 255, 255, 0.9);

  .button {
    max-width: 25.3rem;
    width: 100%;
    margin: 0 auto;
    padding-top: 1.6rem;
    padding-bottom: 1.6rem;

    @media screen and (min-width: $lg-min) {
      flex-shrink: 0;
      margin: 0 0 0 0.5rem;
    }
  }

  &__inner {
    max-width: 90.5rem;
    margin: 0 auto;
    padding: 0 1.5rem;

    @media screen and (min-width: $lg-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 1rem;

    @media screen and (min-width: $lg-min) {
      margin: 0;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2.5rem 0 0;
    padding: 0.5rem;
    overflow: hidden;
    background: #000000;
    border-radius: 50%;

    @include size(5rem, 5rem);

    @media screen and (min-width: $lg-min) {
      margin-right: 4.3rem;
    }
  }

  &__text {
    margin: 0;
    color: #000000;
    font-size: 2rem;
    font-weight: 700;

    b {
      color: $c-main;
      font-size: 2.4rem;
      font-weight: 600;
      margin-left: 1rem;
    }
  }
}