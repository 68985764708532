/*--------------------
  HEADER
--------------------*/

.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 99;
  min-width: 36rem;
  height: $header-height;
  background: #ffffff;
  transition: height ease-out 0.2s;

  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .logo {
    margin-right: auto;

    @include size(20rem, $header-height - 0.5rem);
  }

  &__nav {
    @media screen and (max-width: $lg-max) {
      position: absolute;
      right: 0;
      top: $header-height - 0.6rem;
      display: none;
      min-width: 17rem;
      padding: 1.6rem 1.2rem;
      border-radius: 0.6rem;
      background: #ffffff;
      box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    }

    ul {
      list-style: none;

      @media screen and (max-width: $lg-max) {
        text-align: right;
      }

      @media screen and (min-width: $xl-min) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    li {
      @media screen and (min-width: $xl-min) {
        display: flex;
        margin: 0 0.8rem;
      }

      &:nth-child(n+2) {
        @media screen and (max-width: $lg-max) {
          margin-top: 1rem;
        }
      }
    }

    a {
      display: inline-flex;
      align-items: center;
      color: #484040;
      font-weight: 500;
      text-decoration: none;
      transition: 0.2s color;
      
      @media screen and (max-width: $lg-max) {
        font-size: 1.6rem;
      }

      &:hover,
      &:focus {
        color: $c-main;
      }
    }

    svg {
      flex-shrink: 0;
      max-width: 2.2rem;
      max-height: 1.9rem;

      @media screen and (max-width: $lg-max) {
        order: 2;
        margin-left: 1rem;
      }

      @media screen and (min-width: $xl-min) {
        margin-right: 0.5rem;
      }
    }

    span {
      @media screen and (max-width: $lg-max) {
        order: 1;
      }
    }

    &--active {
      @media screen and (max-width: $lg-max) {
        display: block;
      }
    }
  }

  &__menu-toggle {
    position: relative;
    margin: 0 0 0 3rem;
    padding: 0;
    background: none;
    border: 0;
    border-top: 0.2rem solid $c-text;
    border-bottom: 0.2rem solid $c-text;
    cursor: pointer;

    @include size(1.8rem, 1.4rem);

    @media screen and (min-width: $xl-min) {
      display: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      transition: box-shadow 0.2s;

      @include size(4rem, 4rem);
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      width: inherit;
      height: 0.2rem;
      background: $c-text;
      transform: translate(-50%, -50%);
      transform-origin: center;
      transition: transform 0.2s;
    }

    &--active {
      border-color: transparent;

      span:first-child {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      span:last-child {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}