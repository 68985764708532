/*--------------------
  CONTAINER
--------------------*/

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem;

  @media screen and (min-width: $lg-min) {
    max-width: 117.5rem;
  }
}
