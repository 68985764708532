/*--------------------
  PROS-CONS
--------------------*/

.pros-cons {
  &:not(:last-child) {
    margin: 0 0 2.5rem;
    
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  @media screen and (min-width: $lg-min) {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    padding: 2.5rem 2rem 2.5rem 2.5rem;
    border-radius: 1.5rem;

    @media screen and (min-width: $lg-min) {
      width: 48.8%;
      padding-bottom: 4.5rem;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $md-max) {
        margin-top: 1rem;
      }
    }

    &--pros {
      background: $c-success-light;

      .pros-cons__title {
        color: #1D7C2A;
      }

      .pros-cons__list {
        li {
          &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iIzJBQjAzQyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAxMmMwIDkuODgyIDIuMTE4IDEyIDEyIDEyczEyLTIuMTE4IDEyLTEyUzIxLjg4MiAwIDEyIDAgMCAyLjExOCAwIDEyWm0xNi43MDctMy43MDdhMSAxIDAgMCAxIDAgMS40MTRsLTUgNWExIDEgMCAwIDEtMS40MTQgMGwtMi41LTIuNWExIDEgMCAxIDEgMS40MTQtMS40MTRMMTEgMTIuNTg2bDQuMjkzLTQuMjkzYTEgMSAwIDAgMSAxLjQxNCAwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");
          }
        }
      }
    }

    &--cons {
      background: $c-danger-light;

      .pros-cons__title {
        color: $c-main;
      }

      .pros-cons__list {
        li {
          &::before {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZmlsbD0iI0U1MUEzOCIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAxMmMwIDkuODgyIDIuMTE4IDEyIDEyIDEyczEyLTIuMTE4IDEyLTEyUzIxLjg4MiAwIDEyIDAgMCAyLjExOCAwIDEyWm04LjQ2NS0zLjUzNmExIDEgMCAwIDEgMS40MTQgMEwxMiAxMC41ODZsMi4xMjEtMi4xMjFhMSAxIDAgMSAxIDEuNDE1IDEuNDE0TDEzLjQxNCAxMmwyLjEyMiAyLjEyMWExIDEgMCAwIDEtMS40MTUgMS40MTVsLTIuMTItMi4xMjItMi4xMjIgMi4xMjJhMSAxIDAgMCAxLTEuNDE1LTEuNDE1TDEwLjU4NiAxMiA4LjQ2NSA5Ljg3OWExIDEgMCAwIDEgMC0xLjQxNVoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
          }
        }
      }
    }
  }

  &__title {
    margin: 0 0 1.8rem;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  &__list {
    list-style: none;

    li {
      position: relative;
      padding: 0 0 0 3.5rem;
      color: #333333;
      font-size: 2rem;

      &::before {
        content: "";
        position: absolute;
        top: 0.2rem;
        left: 0;
        background: left top no-repeat;

        @include size(2.4rem, 2.4rem);

        @media screen and (min-width: $lg-min) {
          top: 0.45rem;
        }
      }

      &:nth-child(n+2) {
        margin-top: 2rem;
      }
    }
  }
}