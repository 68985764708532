/*--------------------
  FAQ
--------------------*/

.faq {
  font-size: 2.4rem;

  &:not(:last-child) {
    margin: 0 0 2.5rem;
    
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  &__card {
    padding: 1.2rem 0 0.9rem 1.6rem;
    background: #ffffff;
    border: 0.1rem solid #D9D9D9;
    border-radius: 1.2rem;

    @media screen and (min-width: $lg-min) {
      padding: 2.2rem 0 2.3rem 2.7rem;
    }

    &:nth-child(n+2) {
      margin-top: 1.5rem;
    }
  }

  &__input {
    &:checked {
      + .faq__label {
        &::before {
          transform: rotate(45deg);
        }
      }

      ~ .faq__body {
        max-height: 100vh;
        padding: 1.5rem 3.2rem 0.5rem 0;
        opacity: 1;

        @media screen and (min-width: $lg-min) {
          padding: 2rem 3.2rem 0 0;
        }
      }
    }
  }

  &__label {
    position: relative;
    display: block;
    padding: 0 5.7rem 0 0;
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s color;

    &:hover {
      color: $c-main;

      &::before {
        border-color: $c-main;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 29%;
      right: 2.5rem;
      border-top: 0.3rem solid #000000;
      border-left: 0.3rem solid #000000;
      transform-origin: center center;
      transform: rotate(-135deg);
      transition: 0.2s border-color;

      @include size(1.4rem, 1.4rem);

      @media screen and (min-width: $lg-min) {
        right: 3rem;
      }
    }
  }

  &__body {
    max-height: 0;
    margin: 0;
    opacity: 0;
    transition: max-height 0.2s, opacity 0.15s, padding 0.15s;
  }
}