/*--------------------
  SEARCH-TOGGLE
--------------------*/

.search-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  
  @include size(4.4rem, 4.4rem);

  @media screen and (max-width: $md-max) {
    display: none;
  }

  &:hover {
    svg {
      fill: $c-main;
    }    
  }

  svg {
    fill: #2A5CA8;
    transition: fill 0.2s;

    @include size(1.8rem, 1.8rem);
  }

  &--active {
    &:hover {
      svg {
        fill: #2A5CA8;
      }    
    }

    svg {
      fill: $c-main;
    }
  }
}