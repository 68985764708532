/*--------------------
  LOGO
--------------------*/

.logo {
  position: relative;
  display: flex;
  flex-shrink: 0;

  &__img {
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    margin: auto 0;
  }
}