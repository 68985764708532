/*--------------------
  SLOTS
--------------------*/

.slots {
  position: relative;

  &:not(:last-child) {
    margin: 0 0 2.5rem;
    
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  &__list {
    list-style: none;

    @media screen and (min-width: $md-min) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      @media screen and (min-width: $md-min) {
        width: 49%;
      }

      &:nth-child(n+2) {
        @media screen and (max-width: $sm-max) {
          margin-top: 1rem;
        }
      }

      &:nth-child(n+3) {
        @media screen and (min-width: $md-min) {
          margin-top: 1rem;
        }

        @media screen and (min-width: $lg-min) {
          margin-top: 2rem;
        }
      }
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 3.5rem 0 0;
      text-decoration: none;
      font-weight: 600;
      color: #333333;
      font-size: 1.8rem;
      background: #ffffff;
      border-radius: 0.6rem;
      box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
      transition: color 0.2s;

      @media screen and (min-width: $lg-min) {
        font-size: 2.4rem;
      }

      &::before {
        content: "";
        position: absolute;
        top: 42%;
        right: 1rem;
        border-top: 0.3rem solid #000000;
        border-left: 0.3rem solid #000000;
        transform-origin: center center;
        transform: rotate(-225deg);
        transition: 0.2s border-color;
  
        @include size(1.4rem, 1.4rem);
      }

      &:hover {
        color: $c-main;

        &::before {
          border-color: $c-main;
        }
      }
    }

    p {
      margin: 0;
      padding: 1rem 0 1rem 1.5rem;
      line-height: 1.2;

      @media screen and (min-width: $lg-min) {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;

      }
    }
  }

  &__logo {
    flex-shrink: 0;
    border-radius: 0.6rem;
    overflow: hidden;

    @include size(14.2rem, 9.2rem);

    @media screen and (min-width: $lg-min) {
      @include size(18rem, 11.7rem);
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &__btn {
    display: block;
    max-width: 34rem;
    width: 100%;
    margin: 2rem auto 0;
    padding: 1.7rem 0.5rem;
    font-weight: 600;
    font-size: 1.8rem;
    color: #333333;
    border: none;
    background: $c-cta;
    border-radius: 1.5rem;
    transition: background 0.2s;
    cursor: pointer;

    @media screen and (min-width: $lg-min) {
      max-width: 24.5rem;
    }
    
    &:hover {
      background: darken($c-cta, 15%);
    }
  }
}