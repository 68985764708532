/*--------------------
  INTRO
--------------------*/

.intro {
  padding: 0 0 2rem;
  text-align: center;

  @media screen and (min-width: $lg-min) {
    padding-bottom: 1.5rem;
  }

  @media screen and (min-width: $xl-min) {
    text-align: left;
  }
  
  h1 {
    margin: 0;
    font-size: $f-size-main-title-xs;
    font-weight: 700;
    line-height: 1.1;
    color: $c-main;
    
    @media screen and (min-width: $lg-min) {
      font-size: $f-size-main-title-lg;
    }

    br {
      @media screen and (max-width: $md-max) {
        display: none;
      }
    }
  }
}