/*--------------------
  GREAT-BONUSES
--------------------*/

.great-bonuses {
  margin: 0 0 2.5rem;
    
  @media screen and (min-width: $lg-min) {
    margin-bottom: 4rem;
  }

  &__list {
    list-style: none;

    > li {
      display: flex;
      flex-wrap: wrap;
      max-width: 33rem;
      margin: 0 auto 1.5rem;
      text-align: center;
      border-radius: 1.5rem 1.5rem 0 0;
      overflow-y: hidden;
      background: #ffffff;
      box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);

      @media screen and (min-width: $lg-min) {
        max-width: 100%;
        flex-wrap: nowrap;
        border-radius: 1.5rem;
      }

      > * {
        @media screen and (max-width: $lg-max) {
          width: 100%;
        }
      }
    }
  }

  &__logo {
    width: 100%;
    height: 16.6rem;
    margin: 0 0 1.4rem;
    order: 1;

    @media screen and (min-width: $lg-min) {
      width: 23.7rem;
      height: auto;
      margin: 0;
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__offers {
    order: 3;
    margin: 0 0 0.7rem;
    padding: 0 0.5rem;

    @media screen and (min-width: $lg-min) {
      order: 2;
      width: 27.4%;
      margin: 0;
      padding: 2rem 1rem 1rem 3rem;
      border-right: 0.1rem solid rgba(69, 77, 136, 0.15);
    }
  }

  &__offers-label {
    color: #4C4100;
    font-weight: 500;
    margin: 1rem 0 1.3rem;

    @media screen and (min-width: $lg-min) {
      line-height: 1.3;
      text-align: left;
      margin: 0 0 3.4rem;
    }
  }
  
  &__offers-list {
    display: flex;
    flex-wrap: wrap;

    li {
      list-style: none;
      padding: 1rem;
      font-size: 1.6rem;
      font-weight: 500;
      margin: 0 0.45rem 0.7rem;
      border-radius: 2.4rem;
      background: #EEEEEE;
    }
  }
  
  &__rate {
    order: 2;
    padding: 0 0.5rem;
    font-weight: 700;
    color: #4C4100;
    border-bottom: 0.1rem solid rgba(69, 77, 136, 0.15);
    
    @media screen and (min-width: $lg-min) {
      order: 3;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 27.4%;
      padding: 1rem;
      border-bottom: none;
      border-right: 0.1rem solid rgba(69, 77, 136, 0.15);
    }

    .rating {
      margin: 0 auto 1.4rem;
      background-size: 12.9rem, 2.4rem;

      @include size(12.9rem, 2.4rem);

      @media screen and (min-width: $lg-min) {
        margin-bottom: 0;
      }
  
      &__fill {
        background-size: 12.9rem, 2.4rem;
      }
    }
  }
  
  &__rate-label {
    margin: 0 0 0.5rem;

    @media screen and (min-width: $lg-min) {
      line-height: 1.3;
    }
  }

  &__bottom {
    order: 4;
    padding: 0 2.2rem 2rem;

    @media screen and (min-width: $lg-min) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 27.4%;
      padding: 1rem;
    }

    .button {
      padding-top: 1.55rem;
      padding-bottom: 1.55rem;

      @media screen and (min-width: $lg-min) {
        width: 100%;
        flex-shrink: 0;
        margin: 0 auto;
      }
    }
  }

  &__bottom-text {
    display: none;
    line-height: 1.2;

    @media screen and (min-width: $lg-min) {
      display: block;
      margin: 4rem 0 0;
    }
  }
}