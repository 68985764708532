/*--------------------
  GAMES-MONEY
--------------------*/

.games-money {
  &:not(:last-child) {
    margin: 0 0 4rem;  
  }

  @media screen and (min-width: $md-min) {
    display: flex;
    justify-content: space-between;
  }

  &__card {
    background: #ffffff;
    border-radius: 1.2rem;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    overflow: hidden;

    @media screen and (min-width: $md-min) {
      width: 48.5%;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $sm-max) {
        margin-top: 2rem;
      }
    }
  }

  &__title {
    margin: 0;
    padding: 0.95rem 0.5rem;
    font-size: 2.4rem;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    background: #000000;
  }

  &__list {
    list-style: none;
    font-size: 2rem;
  }

  &__item {
    position: relative;
    padding: 2.5rem 2rem 2.5rem 6rem;
    line-height: 1.3;

    &::before {
      content: "";
      position: absolute;
      top: 2.6rem;
      left: 1.6rem;
      background: left top no-repeat;
      
      @include size(3.2rem, 3.2rem);
    }

    &:nth-child(n+2) {
      border-top: 1px solid rgba(69, 77, 136, 0.15);
    }

    &--pros {
      &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0iIzJBQjAzQyIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAxNkMwIDcuMTYzIDcuMTYzIDAgMTYgMHMxNiA3LjE2MyAxNiAxNi03LjE2MyAxNi0xNiAxNlMwIDI0LjgzNyAwIDE2Wk0xNiAyLjkxQzguNzcgMi45MSAyLjkxIDguNzcgMi45MSAxNmMwIDcuMjMgNS44NiAxMy4wOSAxMy4wOSAxMy4wOSA3LjIzIDAgMTMuMDktNS44NiAxMy4wOS0xMy4wOSAwLTcuMjMtNS44Ni0xMy4wOS0xMy4wOS0xMy4wOVoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjxwYXRoIGZpbGw9IiMyQUIwM0MiIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTkuMTUzIDkuODhhMS40NTUgMS40NTUgMCAwIDEgMi4wNTcgMGwyLjE4MiAyLjE4MmExLjQ1NSAxLjQ1NSAwIDAgMSAwIDIuMDU3bC0yLjE4MiAyLjE4MmExLjQ1NSAxLjQ1NSAwIDAgMS0yLjA1Ny0yLjA1N2wxLjE1NC0xLjE1My0xLjE1NC0xLjE1M2ExLjQ1NSAxLjQ1NSAwIDAgMSAwLTIuMDU4Wm0xMy42OTQgMGExLjQ1NCAxLjQ1NCAwIDAgMSAwIDIuMDU3bC0xLjE1NCAxLjE1NCAxLjE1NCAxLjE1M2ExLjQ1NSAxLjQ1NSAwIDEgMS0yLjA1NyAyLjA1N2wtMi4xODItMi4xODJhMS40NTUgMS40NTUgMCAwIDEgMC0yLjA1N0wyMC43OSA5Ljg4YTEuNDU1IDEuNDU1IDAgMCAxIDIuMDU3IDBaTTkuNjcyIDIwLjMyNmMuMjY1LS40MjkuNzMzLS42OSAxLjIzNy0uNjloMTAuMTgyYTEuNDU1IDEuNDU1IDAgMCAxIDEuMyAyLjEwNWwtMS4zLS42NWMxLjMuNjUgMS4zLjY1MSAxLjMuNjUybC0uMDAyLjAwNC0uMDAzLjAwNy0uMDA5LjAxNi0uMDI0LjA0NmMtLjAyLjAzNi0uMDQ2LjA4My0uMDc5LjEzOC0uMDY1LjExMS0uMTU4LjI2LS4yOC40MzJhNy40MSA3LjQxIDAgMCAxLTEuMTE5IDEuMjQ3Yy0xLjAyNy45MTMtMi42MyAxLjgyMi00Ljg3NSAxLjgyMi0yLjI0NCAwLTMuODQ4LS45MS00Ljg3NS0xLjgyMmE3LjQwNSA3LjQwNSAwIDAgMS0xLjExOS0xLjI0NyA2LjE1IDYuMTUgMCAwIDEtLjM4My0uNjE2bC0uMDA5LS4wMTYtLjAwMy0uMDA3LS4wMDItLjAwM3YtLjAwMWMwLS4wMDEgMC0uMDAyIDEuMy0uNjUybC0xLjMuNjVhMS40NTUgMS40NTUgMCAwIDEgLjA2My0xLjQxNVoiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      }
    }

    &--cons {
      &::before {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzMiAzMiI+PHBhdGggZmlsbD0iI0U1MUEzOCIgZD0iTTEwLjE3NiAyMS4zMjhhMS42IDEuNiAwIDAgMC0uMjA4IDIuMjQgMS42MDEgMS42MDEgMCAwIDAgMi4yNTYuMjA4IDYuMDE2IDYuMDE2IDAgMCAxIDcuNTUyIDAgMS42IDEuNiAwIDAgMCAxLjAyNC4zNjggMS42IDEuNiAwIDAgMCAxLjAyNC0yLjgxNiA5LjI5NiA5LjI5NiAwIDAgMC0xMS42NDggMFptMS4zNi03LjY2NGExLjYgMS42IDAgMCAwIDIuMjU2IDAgMS42IDEuNiAwIDAgMCAwLTIuMjU2IDQuOTI4IDQuOTI4IDAgMCAwLTYuNzg0IDAgMS42IDEuNiAwIDEgMCAyLjI1NiAyLjI1NiAxLjYgMS42IDAgMCAxIDIuMjcyIDBaTTE2IDBhMTYgMTYgMCAxIDAgMCAzMiAxNiAxNiAwIDAgMCAwLTMyWm0wIDI4LjhhMTIuOCAxMi44IDAgMSAxIDAtMjUuNiAxMi44IDEyLjggMCAwIDEgMCAyNS42Wm04Ljk5Mi0xNy4zOTJhNC45MjggNC45MjggMCAwIDAtNi43ODQgMCAxLjYgMS42IDAgMCAwIDIuMjU2IDIuMjU2IDEuNTk4IDEuNTk4IDAgMCAxIDIuMjcyIDAgMS42IDEuNiAwIDAgMCAyLjI1NiAwIDEuNiAxLjYgMCAwIDAgMC0yLjI1NloiLz48L3N2Zz4=")
      }
    }
  }
}