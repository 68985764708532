/*--------------------
  RTP-CARD
--------------------*/

.rtp-card {
  padding: 23.5rem 0.5rem 1.5rem;
  font-size: 2rem;
  border-radius: 1.5rem;
  background: #ffffff;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
  overflow: hidden;
  
  @media screen and (max-width: $sm-max) {
    position: relative;
  }

  @media screen and (min-width: $md-min) {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
  }

  @media screen and (min-width: $lg-min) {
    padding: 4rem 4rem 3.5rem;
  }

  .button {
    max-width: 30rem;
    margin: 0 auto;

    @media screen and (min-width: $md-min) {
      max-width: 100%;
    }
  }

  &__col {
    @media screen and (min-width: $md-min) {
      width: 48%;
    }
  }

  &__title {
    margin: 0 0 0.5rem;
    text-align: center;
    text-decoration: underline;
    color: #484040;
    font-size: 2.4rem;

    @media screen and (min-width: $md-min) {
      text-decoration: none;
      text-align: left;
    }
  }

  &__text {
    margin: 0 0 1rem;
  }

  &__list {
    margin: 0 0 2rem;
    list-style: none;
    color: #000000;
    text-align: center;

    @media screen and (min-width: $md-min) {
      margin-bottom: 0;
    }
    
    li {
      padding: 0.8rem 0;

      @media screen and (min-width: $md-min) {
        display: flex;
        justify-content: space-between;
        padding: 2rem 0 0;
      }

      &:nth-child(n+2) {
        margin-top: 1.5rem;
        border-top: 0.1rem solid rgba(0, 0, 0, 0.1);

        @media screen and (min-width: $md-min) {
          margin-top: 0.5rem;
        }
      }

      > * {
        display: block;
      }
    }
  }

  &__img {
    border-radius: 1.5rem;
    overflow: hidden;
    
    @include size(100%, 21rem);
    
    @media screen and (max-width: $sm-max) {
      position: absolute;
      top: 0;
      left: 0;
    }

    @media screen and (min-width: $md-min) {
      margin: 0 0 2rem;
    }

    @media screen and (min-width: $lg-min) {
      height: 35.6rem;
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }
}