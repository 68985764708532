/*--------------------
  SIDEBAR-LINKS
--------------------*/

.sidebar-links {
  padding: 2.5rem 1rem 2rem 2.5rem;
  list-style: none;
  background: #ffffff;
  border-radius: 1.5rem;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);

  &__item {
    &:nth-child(n+2) {
      margin-top: 1rem;
    }
  }

  &__link {
    font-size: 2rem;
    font-weight: 600;
    color: #484040;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}