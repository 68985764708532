/*--------------------
  BONUSES-BTN
--------------------*/

.bonuses-btn {
  button {
    display: block;
    max-width: 100%;
    width: 33rem;
    margin: 4rem auto 3.5rem;
    padding: 2rem 0.5rem;
    font-size: 1.8rem;
    font-weight: 600;
    background: $c-cta;
    border-radius: 4rem;
    border: none;
    text-decoration: none;
    transition: 0.2s background;
    cursor: pointer;

    @media screen and (min-width: $lg-min) {
      position: relative;
    }

    &::before,
    &::after {
      @media screen and (min-width: $lg-min) {
        content: "";
        position: absolute;
        background: left top / 100% auto no-repeat;
      }
    }

    &::before {
      @media screen and (min-width: $lg-min) {
        top: -2.2rem;
        left: -5.5rem;
        background-image: url("../img/bonuses-btn-1.svg");

        @include size(12.2rem, 9.5rem);
      }
    }

    &::after {
      @media screen and (min-width: $lg-min) {
        top: 0.2rem;
        right: -3rem;
        background-image: url("../img/bonuses-btn-2.svg");

        @include size(8.7rem, 7rem);
      }
    }

    &:hover {
      background: darken($c-cta, 15%);
    }
  }
}