/*--------------------
  FRAME
--------------------*/

.frame {
  padding: 0 0 2.5rem;

  @media screen and (min-width: $lg-min) {
    padding-bottom: 4rem;
  }

  .button {
    width: 100%;
    max-width: 100%;
    padding: 1.5rem 0.5rem;
    text-transform: uppercase;
  }

  &__wrapp {
    position: relative;
    margin: 0 0 1.5rem;
    border-radius: 1.5rem;
    overflow: hidden;

    @media screen and (min-width: $md-min) {
      height: 42rem;
    }
  }

  &__iframe {
    @include size(100%, 50vw);

    @media screen and (min-width: $md-min) {
      height: 100%;
    }
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      filter: brightness(.5);
    }
  }

  &__btn {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    max-width: 16.3rem;
    color: #333333;
    background: $c-cta;
    padding: 1.8rem 0.5rem;
    font-weight: 600;
    font-size: 2rem;
    border-radius: 1rem;
    border: none;
    transition: background 0.2s;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &:hover {
      background: darken($c-cta, 15%);
    }
  }
}