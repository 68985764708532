/*--------------------
  COLS
--------------------*/

.cols {
  padding: 4rem 0 3rem;
  
  .container {
    @media screen and (min-width: $xl-min) {
      display: flex;
      justify-content: space-between;
    }
  }

  .heading {
    margin-bottom: 1rem;
    line-height: 1.2;

    @media screen and (min-width: $lg-min) {
      margin-bottom: 1.5rem;
    }
  }

  &__main {
    @media screen and (min-width: $xl-min) {
      width: 74.5%;
    }

    > .heading {
      text-align: center;
  
      @media screen and (min-width: $xl-min) {
        text-align: left;
      }
    }
  }
  
  &__top {
    list-style: none;
    counter-reset: list-number;
    
    @media screen and (max-width: $md-max) {
      max-width: 50rem;
      margin: 0 auto;
    }

    .top-card {
      position: relative;
      counter-increment: list-number;

      &:nth-child(n+2) {
        margin-top: 1rem;
      }

      &::before {
        content: counter(list-number);
        position: absolute;
        top: 3.5rem;
        left: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #1D2730;
        font-size: 1.6rem;
        font-weight: 900;
        border-radius: 50%;
        border: 0.1rem solid #1D2730;
        
        @include size(3.1rem, 3.1rem);

        @media screen and (min-width: $lg-min) {
          top: 50%;
          left: 2.5rem;
          font-weight: 500;
          border: none;
          transform: translate(0, -50%);

          @include size(auto, auto);
        }
      }

      &--top {
        &::before {
          padding: 0 0 1rem;
          font-size: 1rem;
          border-radius: 0;
          border: none;
          background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAzNSAzNSI+PHBhdGggZmlsbD0iI0U1NjM1MyIgZD0ibTEzLjM3OSAxNi44NDYtNy4yOSAxNS41OCAzLjY2My0uNjYgNi4zNzktMTMuNjMzLTIuNzUyLTEuMjg3WiIvPjxwYXRoIGZpbGw9IiNDRTQxMkQiIGQ9Ik0xNi4xMjggMTguMTMgOS43NDkgMzEuNzY1bDEuODQgMy4yMzUgNy4yOS0xNS41OC0yLjc1Mi0xLjI4OFoiLz48cGF0aCBmaWxsPSIjRTU2MzUzIiBkPSJtMjEuNjE5IDE2Ljg0NCA3LjI5IDE1LjU4LTMuNjYzLS42Ni02LjM3OS0xMy42MzMgMi43NTItMS4yODdaIi8+PHBhdGggZmlsbD0iI0QxNTI0MSIgZD0ibTE4Ljg2MyAxOC4xMyA2LjM3OSAxMy42MzQtMS44NCAzLjIzNS03LjI5LTE1LjU4IDIuNzUyLTEuMjg4WiIvPjxwYXRoIGZpbGw9IiNGQ0Q0NjIiIGQ9Im0yOC4wMzcgMTMuMzE5IDEuMzg4LTEuMzg4LTEuMzg4LTEuMzg4Ljk4Mi0xLjctMS43LS45ODIuNTA4LTEuODk2LTEuODk2LS41MDhWMy40OTRoLTEuOTYzbC0uNTA4LTEuODk2LTEuODk2LjUwOS0uOTgyLTEuNy0xLjcuOTgxTDE3LjQ5NCAwbC0xLjM4OCAxLjM4OC0xLjctLjk4MS0uOTgxIDEuNy0xLjg5Ni0uNTA5LS41MDggMS44OTZIOS4wNTh2MS45NjNsLTEuODk2LjUwOC41MDggMS44OTYtMS43Ljk4Mi45ODEgMS43LTEuMzg4IDEuMzg4IDEuMzg4IDEuMzg4LS45ODEgMS43IDEuNy45ODEtLjUwOCAxLjg5NiAxLjg5Ni41MDh2MS45NjNoMS45NjNsLjUwOCAxLjg5NiAxLjg5Ni0uNTA4Ljk4MSAxLjcgMS43LS45ODEgMS4zODggMS4zODggMS4zODgtMS4zODggMS43Ljk4MS45ODItMS43IDEuODk2LjUwOC41MDgtMS44OTZoMS45NjN2LTEuOTYzbDEuODk2LS41MDhMMjcuMzE5IDE2bDEuNy0uOTgxLS45ODItMS43WiIvPjxwYXRoIGZpbGw9IiNFNTYzNTMiIGQ9Ik0xNy40OTggMjAuNDg5Yy00LjcyIDAtOC41NTktMy44NC04LjU1OS04LjU1OSAwLTQuNzIgMy44NC04LjU1OSA4LjU1OS04LjU1OSA0LjcyIDAgOC41NTkgMy44NCA4LjU1OSA4LjU1OSAwIDQuNzItMy44NCA4LjU1OS04LjU1OSA4LjU1OVptMC0xNS44NDVjLTQuMDE4IDAtNy4yODYgMy4yNjgtNy4yODYgNy4yODYgMCA0LjAxNyAzLjI2OCA3LjI4NiA3LjI4NiA3LjI4NnM3LjI4Ni0zLjI2OSA3LjI4Ni03LjI4NmMwLTQuMDE4LTMuMjY4LTcuMjg2LTcuMjg2LTcuMjg2WiIvPjwvc3ZnPg==") center top / 3.5rem 3.5rem no-repeat;

          @include size(3.5rem, 3.5rem);

          @media screen and (min-width: $lg-min) {
            padding-bottom: 0.6rem;
            background-size: 2.6rem 2.6rem;

            @include size(2.6rem, 2.6rem);
          }
        }
      }
    }
  }

  &__more {
    display: block;
    width: 100%;
    padding: 1.1rem 0.5rem;
    font-weight: 500;
    font-size: 2rem;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 1.5rem;
    border: none;
    cursor: pointer;
    transition: 0.2s background;

    @media screen and (max-width: $md-max) {
      max-width: 50rem;
      margin: 0 auto;
    }

    @media screen and (min-width: $lg-min) {
      margin-top: 1rem;
      color: #333333;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__sidebar {
    @media screen and (max-width: $lg-max) {
      display: none;
    }

    @media screen and (min-width: $xl-min) {
      width: 23.5%;
    }
  }

  &__sidebar-title {
    margin: 0.8rem 0 1.5rem;
    line-height: 1.2;
    color: #484040;
    font-size: 2.4rem;
    font-weight: 700;

    &:not(:first-child) {
      margin-top: 4rem;
    }

    &--alt {
      margin-top: 3rem;
    }
  }

  &--alt {
    padding-top: 0;
  }
}