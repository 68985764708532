/*--------------------
  TEXT-BLOCK
--------------------*/

.text-block {
  margin: 0 0 2rem;
  background: #ffffff;
  padding: 2.5rem 2rem 1.5rem;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
  border-radius: 0.4rem;

  &__title {
    margin: 0 0 1rem;
    font-size: 2.4rem;
    line-height: 1.2;
  }

  &__text {
    margin: 0;
    font-size: 2rem;
  }
}