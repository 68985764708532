/*--------------------
  LIST-TOPS
--------------------*/

.list-tops {
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  ol {
    list-style: none;
    text-align: center;
    counter-reset: list-number;

    @media screen and (max-width: $md-max) {
      max-width: 50rem;
      margin: 0 auto;
    }
  }

  li {
    position: relative;
    padding: 2.5rem 1.5rem 3rem;
    color: #000000;
    border-radius: 1.5rem;
    background: #ffffff;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    counter-increment: list-number;
    overflow: hidden;

    @media screen and (min-width: $lg-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1.5rem 2rem 1.5rem 7.5rem;
      border-radius: 1rem;
    }

    &:nth-child(n+2) {
      margin-top: 1rem;
    }

    &:first-child {
      color: #ffffff;
      background: #101010;

      &::before {
        @media screen and (min-width: $lg-min) {
          background: #101010;
        }
      }  

      button {
        background: $c-cta;
        border-color: $c-cta;
  
        &:hover {
          background: darken($c-cta, 20%);
        }
      }
    }

    &::before {
      content: counter(list-number)".";
      position: absolute;
      top: 3rem;
      left: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2rem;
      font-weight: 600;

      @media screen and (min-width: $lg-min) {
        top: 0;
        bottom: 0;
        left: 0;
        width: 4rem;
        background: #FCFCFC;
      }
    }
  }

  span {
    display: block;

    @media screen and (min-width: $lg-min) {
      padding: 0 0.3rem;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $md-max) {
        margin-top: 1rem;
      }
    }

    &:last-of-type {
      @media screen and (max-width: $md-max) {
        margin: 0 0 2rem;
      }
    }
  }

  button {
    display: block;
    width: 30rem;
    max-width: 100%;
    padding: 1.4rem 0.5rem;
    font-weight: 600;
    color: #333333;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.3);
    border-radius: 1rem;
    transition: 0.2s background;
    cursor: pointer;
    
    @media screen and (max-width: $md-max) {
      margin: 0 auto;
    }

    @media screen and (min-width: $lg-min) {
      width: 13.5rem;
      padding: 1.3rem 0.5rem;  
    }

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    width: 10.5rem;

    @media screen and (max-width: $md-max) {
      margin: 0 auto 2rem;
    }

    @media screen and (min-width: $lg-min) {
      width: 7.8rem;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }
}