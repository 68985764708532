/*--------------------
  IMG-LIST
--------------------*/

.img-list {
  &:not(:last-child) {
    margin: 0 0 2rem;
  }

  ul {
    list-style: none;
    
    @media screen and (min-width: $sm-min) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 56rem;
      margin: 0 auto;
    }

    @media screen and (min-width: $sm-min) {
      max-width: 58rem;
    }

    @media screen and (min-width: $lg-min) {
      max-width: 85rem;
    }

    @media screen and (min-width: $xl-min) {
      max-width: 100%;
    }
  }

  li {
    position: relative;
    width: 26rem;
    min-height: 15.6rem;
    margin: 0 auto 2rem;
    background: #ffffff;
    box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
    border-radius: 1.5rem;
    overflow: hidden;

    @media screen and (min-width: $md-min) {
      width: 27.1rem;
      margin-right: 0;
      margin-left: 0;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: none;
      background: rgba(0, 0, 0, 0.7);

      @include size(100%, 100%);
    }

    &:hover {
      &::before {
        display: block;
      }

      .referral-btn {
        top: 50%;
        transform: translate(-50%, -50%);    
      }
    }
  }

  img {
    object-fit: cover;

    @include size(100%, 100%);
  }

  .referral-btn {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 100%;
    max-width: 22.5rem;
    padding: 0.92rem 0.5rem;
    font-weight: 600;
    font-size: 1.8rem;
    color: #333333;
    background: $c-cta;
    border: none;
    border-radius: 1rem;
    transform: translate(-50%, 0);
    transition: background 0.2s, top 0.3s, transform 0.3s;
    cursor: pointer;

    &:hover {
      @media screen and (min-width: $lg-min) {
        background: darken($c-cta, 15%);
      }
    }
  }

  &--alt {
    li {
      @media screen and (min-width: $lg-min) {
        min-height: 19.4rem;
      }

      &::before {
        background: rgba(72, 64, 64, 0.7);
      }
    }

    .referral-btn {
      @media screen and (min-width: $lg-min) {
        max-width: 13.3rem;
        padding: 0.4rem 0.5rem;
      }
    }
  }
}