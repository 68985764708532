/*--------------------
  BREADCRUMBS
--------------------*/

.breadcrumbs {
  padding: 1.8rem 0 2.2rem;
  text-align: center;
  font-size: 1.6rem;

  ul {
    display: flex;
    justify-content: center;
    list-style: none;

    @media screen and (min-width: $lg-min) {
      justify-content: flex-start;
    }
  }

  li {
    &:not(:last-child) {
      position: relative;
      padding-right: 1.5rem;

      &::before {
        content: "";
        position: absolute;
        top: 0.9rem;
        right: 0.4rem;
        border-top: 0.3rem solid transparent;
        border-bottom: 0.3rem solid transparent;
        border-left: 0.5rem solid #484040;

        @include size(0, 0);
      }
    }
  }

  a {
    color: $c-main;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  span {
    color: #484040;
  }
}