/*--------------------
  CONTENT
--------------------*/

.content {
  padding: 0 0 4rem;
  
  @media screen and (min-width: $lg-min) {
    font-size: 2rem;
    line-height: 1.7;
  }

  &, .container {
    > h2, > h3, > h4, > h5, > h6 {
      margin: 0 0 0.5rem;
      line-height: 1.2;
    }

    > h3, > h4, > h5, > h6 {
      font-size: 2.4rem;
    }
  
    > p {
      margin: 0;
  
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    > ol,
    > ul {
      margin: 0;
      padding: 0 0 0 2rem;
  
      &:not(:last-child) {
        margin-bottom: 2.5rem;
      }

      li {
        &:nth-child(n+2) {
          margin-top: 1.5rem;
        }
      }

      h2, h3, h4, h5, h6 {
        margin: 0 0 0.5rem;
        line-height: 1.2;
      }

      p {
        margin: 0;

        &:not(:last-child) {
          margin-bottom: 1rem;
        } 
      }
    }

    > img {
      max-width: 100%;
      height: auto;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 2.5rem;
      } 
    }

    > a:not([class]) {
      color: $c-main;
      text-decoration: underline;
  
      &:hover {
        text-decoration: none;
      }
    }
  }

  &--alt {
    padding: 2rem 0 0;

    @media screen and (min-width: $lg-min) {
      padding-top: 4rem;
    }
  }
}