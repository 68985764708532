/*--------------------
  TABLE
--------------------*/

.table {
  text-align: center;
  background: #FCFCFC;
  border-radius: 1.5rem;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);
  overflow-x: auto;

  &:not(:last-child) {
    margin-bottom: 4rem;
  }
  
  table {
    width: 100%;
    min-width: 100rem;
    border-radius: inherit;
    background: #ffffff;
    border-collapse: collapse;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 0.1rem solid #e3e4ed;
        }
      }
    }

    th,
    td {
      &:first-child {
        width: 4.2rem;
      }
    }
    
    th {
      padding: 2rem 0.5rem;
      border-bottom: 0.3rem solid #e1e2eb;
      font-size: 1.6rem;

      @media screen and (min-width: $lg-min) {
        font-size: 1.8rem;
      }
    }

    td {
      padding: 1.5rem 0.5rem;
      font-size: 1.8rem;
      line-height: 1.4;

      @media screen and (min-width: $lg-min) {
        font-size: 2rem;
      }

      &:first-child {
        background: #FCFCFC;
      }
    }

    img {
      max-width: 5.7rem;
    }

    .button {
      width: 13.5rem;
      margin: 0 auto;
      padding: 1.5rem 0.5rem;
    }
  }

  &--alt {
    table {
      th, td {
        &:first-child {
          width: auto;
        }
      }

      th {
        padding: 2.65rem 0.5rem;
      }

      td {
        padding: 2.1rem 0.5rem;
        
        &:first-child {
          background: #ffffff;
        }
      }
    }
  }

  &--small {
    table {
      min-width: 70rem;
    }
  }

  &--info {
    table {
      td {
        text-align: left;

        &:first-child {
          width: 20rem;
          padding-left: 4rem;
          border-right: 0.1rem solid #e3e4ed;
        }

        &:not(:first-child) {
          padding-left: 2.8rem;
        }
      }
    }
  }
}