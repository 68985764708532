/*--------------------
  COMMON STYLES
--------------------*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

html {
  font-size: 10px;
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 36rem;
  height: 100vh;
  margin: 0 auto;
  font-weight: 400;
  font-size: $f-size;
  line-height: 1.5;
  font-family: $f-family;
  // color: $c-text;
  color: #333333;
  background: #f9f9f9;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}