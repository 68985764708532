/*--------------------
  INFO-BLOCKS
--------------------*/

.info-blocks {
  &:not(:last-child) {
    margin: 0 0 2.5rem;
  
    @media screen and (min-width: $lg-min) {
      margin-bottom: 4rem;
    }
  }

  p {
    margin: 0;
  }

  &__item {
    @media screen and (min-width: $lg-min) {
      position: relative;
      padding-left: 10.5rem;
    }

    &:nth-child(n+2) {
      margin-top: 2.5rem;

      @media screen and (min-width: $lg-min) {
        margin-top: 1.5rem;
      }
    }

    .info-blocks__head {
      .heading {
        margin: 0;
      }
    }
  }

  &__head {
    display: flex;
    align-items: center;
    margin: 0 0 1rem;
    
    img {
      width: 6.8rem;
      flex-shrink: 0;
      margin: 0 1.5rem 0 0;

      @media screen and (min-width: $lg-min) {
        position: absolute;
        top: 0;
        left: 0;
        width: 8.2rem;
        margin: 0;
      }
    }
  }

  &--alt {
    .info-blocks__head {
      .heading {
        color: #333333;
        font-size: 2.4rem;
      }
    }
  }
}