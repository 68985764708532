/*--------------------
  BEST-CASINOS
--------------------*/

.best-casinos {
  text-align: center;

  &:not(:last-child) {
    margin: 0 0 2rem;
  }

  ul {
    list-style: none;

    @media screen and (min-width: $lg-min) {
      display: flex;
      justify-content: space-between;
    }
  }

  li {
    max-width: 60rem;
    margin-right: auto;
    margin-left: auto;
    padding: 2.3rem 1.5rem 1.3rem;
    background: #101010;
    border-radius: 1.5rem;

    @media screen and (min-width: $lg-min) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 31.7%;
      margin-right: 0;
      margin-left: 0;
    }

    &:nth-child(n+2) {
      @media screen and (max-width: $md-max) {
        margin-top: 1.5rem;
      }
    }
  }

  .rating {
    margin: 0 auto 2.5rem;
    background-size: 11rem, 2rem;

    @include size(11rem, 2rem);

    &__fill {
      background-size: 11rem, 2rem;
    }
  }

  .referral-btn {
    width: 100%;
    padding: 1rem 0.5rem;
    font-size: 1.8rem;
    color: #333333;
    font-weight: 600;
    background: $c-cta;
    border: none;
    border-radius: 1rem;
    transition: background 0.2s;
    cursor: pointer;

    @media screen and (min-width: $lg-min) {
      margin-top: auto;
    }

    &:hover {
      background: darken($c-cta, 15%);
    }
  }

  &__logo {
    max-width: 10rem;
    margin: 0 auto 3rem;
    height: 4.4rem;
  }

  &__name {
    margin: 0 0 0.5rem;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.2;
    color: #ffffff;
  }

  &__bonus {
    margin: 0 0 3rem;
    font-size: 2.2rem;
    color: #FDC246;
    
    &::before {
      content: "";
      display: inline-block;
      position: relative;
      top: 0.2rem;
      flex-shrink: 0;
      margin: 0 0.7rem 0 0;
      background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggc3Ryb2tlPSIjRkNDMjQ2IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgZD0iTTMgMTFjMCAxMC4yMjIgMCAxMiA5IDEyczktMS43NzggOS0xMk0xMiAzLjVhMi41MDEgMi41MDEgMCAwIDAtNSAwQTIuNSAyLjUgMCAwIDAgOS41IDZIMTJtMC0yLjVWNm0wLTIuNWEyLjUwMSAyLjUwMSAwIDAgMSA1IDBDMTcgNC44OCAxNS44MTUgNiAxNC41IDZIMTJtMCAwdjE3bTAtMTdoOGMyIDAgMyAuNSAzIDIuNVMyMiAxMSAyMCAxMUgzLjVDMiAxMSAxIDEwLjUgMSA4LjVTMS44ODIgNiAzLjUgNkgxMloiLz48L3N2Zz4=") left top / 100% auto no-repeat;

      @include size(2.2rem, 2.2rem);
    }
  }
}