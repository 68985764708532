/*--------------------
  SIDEBAR-TOP
--------------------*/

.sidebar-top {
  list-style: none;
  counter-reset: list-number;
  background: #ffffff;
  border-radius: 1.5rem;
  box-shadow: 0 0 8rem rgba(0, 0, 0, 0.05);

  &__item {
    counter-increment: list-number;

    &--top {
      background: #101010;
      border-radius: 1.5rem;

      .sidebar-top__link::before {
        color: #f9f9f9;
      }

      .sidebar-top__name,
      .sidebar-top__desc {
        color: $c-cta;
      }
    }
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem 1rem 1rem 3.5rem;
    text-decoration: none;

    &:hover {
      .sidebar-top__name,
      .sidebar-top__desc {
        color: $c-main;
      }
    }

    &::before {
      content: counter(list-number);
      position: absolute;
      top: 50%;
      left: 1.5rem;
      color: #1D2730;
      font-size: 1.6rem;
      font-weight: 500;
      transform: translate(0, -50%);
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    margin: 0 1rem 0 0;
    background: #000000;
    border: 0.1rem solid #4c4c4c;
    border-radius: 50%;
    overflow: hidden;

    @include size(5rem, 5rem);

    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  &__info {
    flex-grow: 1;
    flex-shrink: 0;
  }

  &__name,
  &__desc {
    margin: 0;
    line-height: 1.3;
    transition: color 0.2s;
    text-transform: uppercase;
  }  

  &__name {
    color: #000000;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__desc {
    color: #262626;
    font-size: 1.4rem;
  }
}